<template>
    <!-- component -->
    <section class="bg-white">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="font-light text-gray-700 text-base sm:text-lg md:text-xl">
          <!-- Ajuste de tamaño de texto para pantallas pequeñas -->
          <h2 class="mb-4 text-4xl font-extrabold text-gray-900">Quiénes Somos</h2>
          <p class="mb-4 text-justify">
            Somos una plataforma digital diseñada para brindar a los inversores en Chile una manera clara y eficiente de
            visualizar todas sus inversiones y rentabilidades. Nuestro objetivo es simplificar el acceso a la información
            financiera, permitiéndote consultar el rendimiento de tus fondos en un solo lugar, de forma rápida y segura. Con
            una interfaz intuitiva y herramientas visuales avanzadas, hacemos que gestionar y entender tus inversiones sea
            más sencillo que nunca.
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8">
          <img class="mt-4 w-full lg:mt-10 rounded-lg" src="../assets/equipo1.png" alt="office content 2">
          <img class="w-full rounded-lg" src="../assets/equipo2.png" alt="office content 1">
        </div>
      </div>
    </section>
  
    <section class="bg-white">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <!-- Cambiar el orden en pantallas pequeñas -->
        <div class="font-light text-gray-700 text-base sm:text-lg md:text-xl lg:order-2">
          <!-- Ajuste de tamaño de texto para pantallas pequeñas -->
          <h2 class="mb-4 text-4xl font-extrabold text-gray-900">Nos Enfocamos en el Cliente</h2>
          <p class="mb-4 text-justify">
            Nuestro enfoque está centrado en ofrecerte la mejor experiencia al gestionar y visualizar tus inversiones. Sabemos
            lo importante que es tener acceso a información precisa y actualizada, por lo que hemos creado una plataforma fácil
            de usar que te permite monitorear el rendimiento de tus fondos en tiempo real. Ponemos la transparencia y la
            claridad al frente, para que siempre tengas una visión completa de tus activos y puedas tomar decisiones financieras
            con total confianza.
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8 lg:order-1">
          <!-- Aplicar orden inverso para pantallas grandes -->
          <img class="w-full rounded-lg" src="../assets/equipo3.png" alt="office content 3">
          <img class="mt-4 w-full lg:mt-10 rounded-lg" src="../assets/equipo4.png" alt="office content 2">
        </div>
      </div>
    </section>
  </template>
  
  

<script>
export default {
name: 'QuienesSomos',

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
