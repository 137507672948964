<template>
    <!-- component -->
    <section class="bg-white">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <!-- Texto primero en pantallas pequeñas y luego las imágenes en pantallas grandes -->
        <div class="font-light text-gray-700 text-base sm:text-lg md:text-xl order-1 lg:order-2">
          <h2 class="mb-4 text-4xl font-extrabold text-gray-900">Agrupación Inteligente y Personalizada</h2>
          <p class="mb-4 text-justify">
            Nuestra plataforma transforma la forma en que administras tus inversiones al permitirte crear y gestionar grupos
            completamente personalizados según tus propias estrategias y metas financieras. Olvida el caos de tener que seguir
            cada inversión por separado: ahora puedes organizar tus activos en categorías que reflejen tu visión de crecimiento,
            ya sea dividiendo por tipo de activo, sector económico, horizonte de tiempo o incluso riesgo. Imagina poder separar
            tus inversiones en fondos mutuos nacionales, acciones internacionales o cualquier otra combinación que consideres
            clave para tu portafolio. Con esta flexibilidad, puedes realizar un seguimiento detallado de cada grupo, optimizando
            tu análisis y asegurando que todas las piezas de tu estrategia de inversión trabajen en armonía hacia tus objetivos.
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8 order-2 lg:order-1">
          <img class="w-full rounded-lg" src="../assets/services10.png" alt="office content 1">
          <img class="mt-4 w-full lg:mt-10 rounded-lg" src="../assets/services11.png" alt="office content 2">
        </div>
      </div>
    </section>
  
    <section class="bg-white">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="font-light text-gray-700 text-base sm:text-lg md:text-xl order-1">
          <h2 class="mb-4 text-4xl font-extrabold text-gray-900">Rendimiento Total Sin Barreras</h2>
          <p class="mb-4 text-justify">
            Llevar un registro de tus inversiones es más fácil y eficiente cuando puedes ver el rendimiento completo de tu
            portafolio en un solo lugar, sin importar dónde esté invertido tu dinero. A diferencia de otros servicios que
            excluyen fondos locales y activos menos convencionales, nuestra herramienta te ofrece un análisis exhaustivo que
            incluye tus inversiones en fondos chilenos, fondos internacionales, acciones y más. Podrás observar con precisión
            cómo se comporta cada inversión, comparar rendimientos y estudiar los resultados históricos sin perder de vista
            ningún detalle. Esto significa tener acceso a métricas claras y completas, dándote una ventaja al tomar decisiones
            informadas y asegurando que estés siempre un paso adelante en el manejo de tu portafolio, desde la diversificación
            hasta la optimización de tu rentabilidad.
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8 order-2">
          <img class="mt-4 w-full lg:mt-10 rounded-lg" src="../assets/services9.png" alt="office content 2">
          <img class="w-full rounded-lg" src="../assets/services8.png" alt="office content 1">
        </div>
      </div>
    </section>
  </template>
  
  
  

<script>
export default {
name: 'ServiciosPage',

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
