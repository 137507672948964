import { createApp } from 'vue'
import App from './App.vue'
import LoginInicio from './components/LoginInicio.vue'
import { createRouter, createWebHistory } from 'vue-router'
import QuienesSomos from './pages/QuienesSomos.vue'
import DataFondos from './pages/DataFondos.vue'
import RegistrarInicio from './components/RegistrarInicio.vue'
import axios from 'axios'
import EsperandoValidacion from './components/EsperandoValidacion.vue'
import store from './store/'; // Si usas `index.js`, la ruta es `./store`
import './assets/tailwind.css'

import VerificarMail from './components/VerificarMail.vue'
import SetupDoble from './components/TwoFactorAuth.vue'
import VerificarDoble from './components/VerificarDoble.vue'
import PaginaInicio from './pages/PaginaInicio.vue'
import RequestPass from './components/RequestPass.vue'
import ResetPass from './components/ResetPass.vue'
import AddFondos from './pages/AddFondos.vue'
import SesionesExistentes from './pages/SesionesExistentes.vue'
import CreateGrupo from './pages/CreateGrupo.vue'
import ContactoPage from './pages/ContactoPage.vue'
import InfoPage from './pages/InfoPage.vue'
import ServiciosPage from './pages/ServiciosPage.vue'



axios.defaults.baseURL = 'https://exemplary-youthfulness-production.up.railway.app';
axios.defaults.withCredentials = true;

const routes = [
    { path: '/', name: 'PaginaInicio', component: PaginaInicio},
    { path: '/login', name: 'LoginInicio', component: LoginInicio},
    { path: '/about', name: 'Quienes Somos', component: QuienesSomos},
    { path: '/contact', name: 'ContactoFondos', component: ContactoPage},
    { path: '/services', name: 'ServiciosPage', component: ServiciosPage},
    { path: '/tipo-fondos', name: 'TiposFondos', component: InfoPage},
    { path: '/data', name: 'Data Fondos', component: DataFondos , meta: { requiresAuth: true } },
    { path: '/register', name: 'Registrar', component: RegistrarInicio},
    { path: '/confirmacion-registro', name: 'Validacion', component: EsperandoValidacion},
    { path: '/verify-email', name: 'Verificar', component: VerificarMail },
    { path: '/reset', name: 'Reset', component: RequestPass },
    { path: '/reset-password', name: 'ResetPass', component: ResetPass },
    { path: '/setup-2fa', name: 'SetupDoble', component: SetupDoble },
  { path: '/verify-2fa', name: 'VerificarDoble', component: VerificarDoble },
  { path: '/add', name: 'AddFondos', component: AddFondos , meta: { requiresAuth: true } },
  { path: '/create-grupos', name: 'Crear grupos', component: CreateGrupo , meta: { requiresAuth: true } },
  { path: '/sessions', name: 'SesionesExistentes', component: SesionesExistentes , meta: { requiresAuth: true } },
    { path: '/:catchAll(.*)', redirect: '/' },
  ]
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
          top: +90, // Ajusta este valor según el padding-top que tengas
        };
      } else if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
  });
  
  
  
  router.beforeEach(async (to, from, next) => {
    // Verificar si la ruta a la que se intenta acceder es de Login o Registrar
    if (to.name === 'LoginInicio' || to.name === 'Registrar') {
      const isAuthenticated = store.state.isAuthenticated;
  
      // Si el usuario está autenticado, redirigir a la página de inicio
      if (isAuthenticated) {
        return next({ name: 'PaginaInicio' });
      }
    }
  
    // Verificar si la ruta requiere autenticación
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Verificar siempre el estado de autenticación con el servidor
      await store.dispatch('checkAuthStatus');
  
      if (store.state.isAuthenticated) {
        next(); // Si está autenticado, permitir la navegación
      } else {
        await store.dispatch('logout'); // Si no está autenticado, cerrar la sesión localmente
        next({ name: 'LoginInicio' }); // Redirigir al login
      }
    } else {
      next(); // Si la ruta no requiere autenticación, continuar
    }
  });
  
  

// Interceptor de respuesta para manejar solo el código 419}
axios.interceptors.response.use(
    response => response,  // Deja pasar las respuestas exitosas
    async (error) => {
      const originalRequest = error.config;

      // Manejo de código 419: Sesión expirada
      if (error.response && error.response.status === 419 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Cerrar sesión si la sesión ha expirado (código 419)
          await store.dispatch('logout');
          // Redirigir al login utilizando Vue Router (si estás dentro de un componente)
          app.config.globalProperties.$router.push({ name: 'LoginInicio' });

        } catch (err) {
          return Promise.reject(err);
        }

        return Promise.reject(error);  // Rechazar el error 419
      }

      // Rechazar cualquier otro error
      return Promise.reject(error);
    }
);



const app = createApp(App)

  // Añadir axios como una instancia global
app.config.globalProperties.$axios = axios
app.use(store);  // Integrar el store en la aplicación
  // Usar router y montar la aplicación
app.use(router);
app.mount('#app')