<template>
  <HeaderGlobal></HeaderGlobal>
  <div v-if="shouldShowFooter" class="pt-[84px]"></div>

  <main><router-view></router-view></main>

  <FooterPrincipal v-if="shouldShowFooter"></FooterPrincipal>

</template>

<script>

import HeaderGlobal from './components/HeaderGlobal.vue';
import FooterPrincipal from './components/FooterPrincipal.vue'

import equipo1 from '@/assets/equipo1.png';
import equipo2 from '@/assets/equipo2.png';
import equipo3 from '@/assets/equipo3.png';
import equipo4 from '@/assets/equipo4.png';

import servicio10 from '@/assets/services10.png';
import servicio11 from '@/assets/services11.png';
import servicio9 from '@/assets/services9.png';
import servicio8 from '@/assets/services8.png';

import loginImage from '@/assets/finance-14.jpg';

export default {
  name: 'App',
  components: {
    HeaderGlobal,
    FooterPrincipal
  },
  mounted() {
    this.precargarImagenes();
  },
  computed: {
    shouldShowFooter() {
      // Lista de rutas donde NO quieres mostrar el footer
      const routesWithoutFooter = ['/login', '/register'];
      // Verifica si la ruta actual está en esa lista
      return !routesWithoutFooter.includes(this.$route.path);
    }

    
  },
  methods: {
    precargarImagenes() {
      const imagenes = [
        equipo1,
        equipo2,
        equipo3,
        equipo4,
        servicio10,
        servicio11,
        servicio9,
        servicio8,
        loginImage
        // Añadir todas las imágenes que se usarán en otras páginas
      ];

      imagenes.forEach((src) => {
        const img = new Image();
        img.src = src; // Esto carga la imagen en segundo plano
      });
    },
  },
  
}

</script>

<style>
@import './assets/style.css';
</style>
