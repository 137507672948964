<template>
  <div class="bg-white dark:bg-gray-900">
    <div class="flex justify-center h-screen">
      <div class="bg-cover lg:block lg:w-2/3" :style="{ backgroundImage: `url(${backgroundImage})` }">

        <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
          <div>
            <h2 class="text-4xl font-bold text-white">Largo Plazo</h2>
            <p class="max-w-xl mt-3 text-white">
              Se refiere a un horizonte de inversión que abarca más de 3 años y, a menudo, se extiende a 10, 20 años o más. Las inversiones a largo plazo están destinadas a generar un crecimiento sostenido y se enfocan en objetivos a futuro, como la jubilación o la compra de una propiedad. Ejemplos incluyen fondos de pensiones, inversiones en bienes raíces, y acciones o bonos mantenidos durante un tiempo prolongado para maximizar el rendimiento a través de la capitalización.
            </p>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1">
          <div class="text-center">
            <h2 class="text-3xl sm:text-4xl font-bold text-center text-gray-700 dark:text-white">Regístrate</h2>
            <p class="mt-3 text-base sm:text-lg text-gray-500 dark:text-gray-300">Completa tus datos</p>
          </div>

          <div class="mt-8">
            <form @submit.prevent="register">
              <div>
                <label for="nombre" class="block mb-2 text-sm sm:text-base text-gray-600 dark:text-gray-200">Nombre</label>
                <input
                  v-model="username"
                  type="text"
                  name="nombre"
                  id="nombre"
                  placeholder="Luis Perez"
                  class="block w-full px-4 py-2 mt-2 text-sm sm:text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div class="mt-6">
                <div>
                  <label for="email" class="block mb-2 text-sm sm:text-base text-gray-600 dark:text-gray-200">Email</label>
                  <input
                    v-model="email"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@example.com"
                    class="block w-full px-4 py-2 mt-2 text-sm sm:text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
              </div>
              <div class="mt-6">
                <div class="flex justify-between mb-2">
                  <label for="password" class="text-sm sm:text-base text-gray-600 dark:text-gray-200">Contraseña</label>
                </div>
                <input
                  v-model="password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Tu contraseña"
                  class="block w-full px-4 py-2 mt-2 text-sm sm:text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div class="mt-6">
                <button
                  class="w-full px-4 py-2 text-sm sm:text-base tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Registrarse
                </button>
              </div>
            </form>

            <p class="mt-6 text-sm sm:text-base text-center text-gray-400">
              Ya tienes una cuenta?
              <router-link to="/login" class="text-blue-500 hover:underline">Ingresar</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import backgroundImage from '../assets/finance-14.jpg';

  export default {
    data() {
      return {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        errorMessage: '',
        backgroundImage
      };
    },
    methods: {
      async register() {
        if (this.password !== this.confirmPassword) {
          this.errorMessage = "Las contraseñas no coinciden";
          return;
        }
  
        try {
          const response = await this.$axios.post('/register', {
            username: this.username,
            email: this.email,
            password: this.password
          });
          console.log(response.data)
          // Si el registro es exitoso, redirige a la página de confirmación
          this.$router.push('/confirmacion-registro');
        } catch (error) {
          this.errorMessage = error.response.data.message || 'Error al registrarse';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Tu CSS */
  </style>
  